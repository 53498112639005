import axios from "axios";

//https://ik.imagekit.io/5tgxhsqev/saffronstays-media/w_450,c_scale/apdeag1ax6xuq1owwjcv.png

export const MultiUploadApi = async (
	file,
	setOpen,
	setUploadPercentage,
	type = "image",
) => {
	setOpen(true);
	const config = {
		onUploadProgress: (progressEvent) => {
			setUploadPercentage(
				Math.round((progressEvent.loaded * 100) / progressEvent.total),
			);
		},
	};
	const formData = new FormData();
	formData.append("file", file);
	// formData.append("upload_preset", "user_generated_content");
	const res = await axios.post(
		"https://go.saffronstays.com/api/upload-file",
		formData,
		config,
	);
	setOpen(false);
	setUploadPercentage(0);
	return res.data;
};
