//returns the image from the saffronstays cloudinary server

interface getImgProps {
	id: any;
	version?: string;
	quality?: string;
	height?: string;
	width?: string;
}

export const getImg = ({
	id = "",
	version = "",
	quality = "50",
	height = "",
	width = "",
}: getImgProps) => {
	if (!id) return "";
	if (width)
		return `https://ik.imagekit.io/5tgxhsqev/saffronstays-media/image/upload/${id}?tr=w-${width},h-${height},q-${quality},f-webp`;
	return `https://ik.imagekit.io/5tgxhsqev/saffronstays-media/image/upload/${id}?tr=q-${quality},f-webp`;
};

export const getImgWithLogo = ({
	id = "",
	version = "",
	quality = "50",
	height = "",
	width = "",
}: getImgProps) => {
	if (!id) return "";
	if (width)
		//return `https://ik.imagekit.io/5tgxhsqev/saffronstays-media/f_auto,fl_force_strip.progressive:steep,q_${quality},w_${width},h_${height},c_fill/e_vibrance:55/e_auto_brightness/v${version}/${id}.webp`;
		return `https://ik.imagekit.io/5tgxhsqev/saffronstays-media/image/upload/${id}?tr=w-${width},h-${height},q-${quality},f-webp`;
	//return `https://ik.imagekit.io/5tgxhsqev/saffronstays-media/f_auto,fl_force_strip.progressive:steep,c_limit,q_80,e_auto_contrast,h_1000,w_1500/e_vibrance:55/e_auto_brightness/v${version}/${id}.webp`;
	return `https://ik.imagekit.io/5tgxhsqev/saffronstays-media/image/upload/${id}?tr=q-${quality},f-webp`;
};

export default getImg;
